/* TOC

- Adjustments: Video Layout
- Navigation
- Search
- Masthead
- Masthead › small-only
- Masthead › medium-only
- Masthead › large-only
- Masthead › xlarge-up
- Breadcrumb
- Meta
- Jump to top
- Footer
- Subfooter
- CSS-Classes to add margin at top or bottom

*/


/* move clearing lightbox image descriptions to the right,   padding-bottom: 0px;*/
.clearing-caption {
  text-align: right;
}

.clearing-caption div p{
    margin-bottom: inherit;
    background: rgba(51,51,51, 0.8);
}

.clearing-link {
    background: rgba(51,51,51, 0.8);
}

.clearing-close, .clearing-main-prev, .clearing-main-next {
    border: none;
    text-shadow: 2px 0 0 #333, 0 -2px 0 #333, 0 2px 0 #333, -2px 0 0 #333;
}

.clearing-close:hover, .clearing-main-prev:hover, .clearing-main-next:hover {
    color: $ci-2;
    border: none;
}

/* below is the verbatim copy from the foundation's medium-up section to make sure the clearing controls work the same way on the small screen */
@media #{$small-only} {
      .clearing-main-prev,
      .clearing-main-next {
        position: absolute;
        height: 100%;
        width: 40px;
        top: 0;
        & > span {
          position: absolute;
          top: 50%;
          display: block;
          width: 0;
          height: 0;
          border: solid $clearing-arrow-size;
          &:hover { opacity: 0.8; }
        }
      }
      .clearing-main-prev {
        #{$default-float}: 0;
        & > span {
          #{$default-float}: 5px;
          border-color: rgba(51,51,51, 0.9);
          border-#{$opposite-direction}-color: $clearing-arrow-color;
        }
      }
      .clearing-main-next {
        #{$opposite-direction}: 0;
        & > span {
          border-color: rgba(51,51,51, 0.9);
          border-#{$default-float}-color: $clearing-arrow-color;
        }
      }
    
      .clearing-main-prev.disabled,
      .clearing-main-next.disabled { opacity: 0.3; }

      .clearing-close {
        position: absolute;
        top: 10px;
        #{$opposite-direction}: 20px;
        padding-#{$default-float}: 0;
        padding-top: 0;
      }
}

//$block-grid-default-spacing: 0 0 0 0;
//$align-block-grid-to-grid: true;

.portfolio-front {
  @include block-grid($per-row:7, $spacing: 2px, $base-style: true);
  overflow: hidden;
}

.portfolio-thumb {
  object-fit: cover;
/*
  width: 120px;
  height: 500px;
   -webkit-filter: grayscale(80%);
       -moz-filter: grayscale(80%);
         -o-filter: grayscale(80%);
        -ms-filter: grayscale(80%);
            filter: grayscale(80%);
*/
}
/*
.portfolio-thumb:hover {
   -webkit-filter: grayscale(0%);
       -moz-filter: grayscale(0%);
         -o-filter: grayscale(0%);
        -ms-filter: grayscale(0%);
            filter: grayscale(0%);
}
*/

figcaption {
    position: absolute;
    top: 200px;
    left: 0;
    width: 100%;
    padding: 0 0 30px 0;
    background: rgba(44,63,82,.8);
    opacity: 0;
    text-align: center;
    backface-visibility: hidden;
    transition: transform 0.3s, opacity 0.3s;
}

figure:hover figcaption {
  opacity: 1;
}

.tint {
    position: relative;
    /*float: left;*/
    cursor: pointer;
}

.tint:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,40,20, 0.4);
    -moz-transition: background .3s linear;
    -webkit-transition: background .3s linear;
    -o-transition: background .3s linear;
    transition: background .3s linear;
}

.tint:hover:before {
    background: none;
}

// Backstretch (header in the masthead) gradient

.backstretch:before {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, $ci-6, $ci-2, rgba(0,0,0,0), rgba(0,0,0,0));
    position: absolute;
}

@-webkit-keyframes rotate {
   from { -webkit-transform: rotate(0deg); }
   to { -webkit-transform: rotate(360deg); }
}
@-moz-keyframes rotate {
   from { -moz-transform: rotate(0deg); }
   to { -moz-transform: rotate(360deg); }
}
@-o-keyframes rotate {
   from { -o-transform: rotate(0deg); }
   to { -o-transform: rotate(360deg); }
}
@keyframes rotate {
   from { transform: rotate(0deg); }
   to { transform: rotate(360deg); }
}

#spinner {
    display: none;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    border: solid 3px;
    border-color: #555 #fff;
    -webkit-border-radius: 1000px;
    border-radius: 1000px;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotate;
    -moz-animation-duration: 1.5s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -o-animation-name: rotate;
    -o-animation-duration: 1.5s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
    animation-name: rotate;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

/* Adjustments: Video Layout
------------------------------------------------------------------- */

body.video,
body.video #masthead-no-image-header { background: #000; }
body.video #masthead-no-image-header { margin-bottom: 60px; }
body.video h1,
body.video h2,
body.video h3,
body.video h4,
body.video h5,
body.video h6,
body.video p,
body.video a,
body.video blockquote:before,
body.video blockquote:after,
body.video cite a, { color: #fff; }
body.video cite a:visited, { color: #fff; }
body.video cite { color: #fff; }



/* Navigation
------------------------------------------------------------------- */

#navigation {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.2);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,.2);
}



/* Search
------------------------------------------------------------------- */

.no-js form#search {
  display: none;
}



/* Masthead
------------------------------------------------------------------- */

#masthead {
    background-color: $primary-color;
}
#masthead-no-image-header {
    background-color: $primary-color;
}
#masthead-with-text {
    text-align: left;
    font-size: rem-calc(54);
    font-family: $header-font-family;
    color: #fff;
    text-shadow: 0 2px 3px rgba(0,0,0,.4);
    background-color: $ci-2;
}
#masthead-no-image-header {
    height: 175px;
}
#masthead-no-image-header #logo img {
    margin-top: 60px;
}

/* Masthead › small-only
------------------------------------------------------------------- */

@media #{$small-only} {
    #logo img {
        display: none;
    }
    #masthead {
        height: 50px;
    }
    #masthead-with-pattern {
        padding: 15px 0;
    }
    #masthead-with-background-color {
        padding: 15px 0;
    }
    #masthead-with-text {
        padding: 5px 0;
        font-size: rem-calc(36);
        color: transparent;
        height: 50px;
    }
    #masthead-with-text .row{
        display: none;
    }
    #masthead-no-image-header {
        display: none;
    }
}


/* Masthead › medium-only
------------------------------------------------------------------- */

@media #{$medium-only} {
    #logo img {
        margin-top: 60px;
    }
    #masthead {
        height: 120px;
    }
    #masthead-with-pattern {
        padding: 20px 0;
    }
    #masthead-with-background-color {
        padding: 20px 0;
    }
    #masthead-with-text {
        padding: 20px 0;
        font-size: rem-calc(46);
        height: 120px;
    }
}


/* Masthead › large-only
------------------------------------------------------------------- */

@media #{$large-only} {
    #logo img {
        margin-top: 80px;
    }
    #masthead {
        height: 170px;
    }
    #masthead-with-pattern {
        padding: 30px 0;
    }
    #masthead-with-background-color {
        padding: 30px 0;
    }
    #masthead-with-text {
        padding: 50px 0;
        height: 150px;
    }
}


/* Masthead › xlarge-up
------------------------------------------------------------------- */

@media #{$xlarge-up} {
    #logo img {
        margin-top: 110px;
    }
    #masthead {
        height: 170px;
    }
    #masthead-with-pattern {
        padding: 45px 0;
    }
    #masthead-with-background-color {
        padding: 45px 0;
    }
    #masthead-with-text {
        padding: 55px 0;
        height: 170px;
    }
}


#title-image-small {
    height: 240px;
}
#title-image-large {
    height: 520px;
}
#title-image-index-small {
    height: 120px;
}
#title-image-index-large {
    height: 260px;
}



/* Breadcrumb
------------------------------------------------------------------- */

#breadcrumb {
    background: scale-color($grey-1, $lightness: 55%);
    border-top: 1px solid scale-color($grey-1, $lightness: 45%);
    border-bottom: 1px solid scale-color($grey-1, $lightness: 45%);
}
.breadcrumbs>.current {
  font-weight: bold;
}


/* Meta
------------------------------------------------------------------- */

#page-meta, #page-meta a {
    color: $grey-5;
}

#page-meta .button {
    background: $grey-5;
    border: 0;
}
#page-meta .button {
    color: #fff;
}
#page-meta .button:hover {
    background: $primary-color;
}
.meta-info p {
  font-size: rem-calc(13);
  color: scale-color($grey-1, $lightness: 40%);
}
  .meta-info a {
    text-decoration: underline;
    color: scale-color($grey-1, $lightness: 40%);
  }
  .meta-info a:hover {
    text-decoration: none;
    color: $secondary-color;
  }



/* Jump to top
------------------------------------------------------------------- */

#up-to-top {
    padding: 10px 0 10px 0;
}
#up-to-top a {
    font-size: 24px;
    padding: 5px;
    border-radius: 3px;
}
#up-to-top a:hover {
    background: $grey-2;
}



/* Footer
------------------------------------------------------------------- */

#footer-content p,
#footer-content li {
    font-size: rem-calc(13);
    font-weight: 300;
}

#footer {
    padding-top: 30px;
    padding-bottom: 20px;
    background: $footer-bg;
    color: $footer-color;
    }

    #footer a {
        color: $footer-link-color;
    }
    #footer h4,
    #footer h5 {
        letter-spacing: 1px;
        color: #fff;
        text-transform: uppercase;
    }



/* Subfooter
------------------------------------------------------------------- */

#subfooter {
    background: $subfooter-bg;
    color: $subfooter-color;
    padding-top: 30px;
}

#subfooter-left ul.inline-list {
    float: left;
}

#subfooter-right ul.inline-list {
    float: right;
}

.credits a {
    color: $subfooter-link-color;
    border: 0;
    text-transform: uppercase;
    &:hover {
        color: #fff;
  }
}

.social-icons {
    margin-bottom: 10px !important;

// Beware of SCSS-Syntax here
  li {
    padding: 0 0 20px 0;
  }
  a {
    font-size: rem-calc(23);
    display: block;
    width: 36px;
    border-radius: 50%;
    color: $subfooter-bg;
    background: $subfooter-color;
    text-align: center;
    &:hover {
      background: $subfooter-bg;
      color: #fff;
    }
  }
}



/* CSS-Classes to add margin at top or bottom
------------------------------------------------------------------- */

.t10 { margin-top: 10px !important; }
.t15 { margin-top: 15px !important; }
.t20 { margin-top: 20px !important; }
.t30 { margin-top: 30px !important; }
.t50 { margin-top: 50px !important; }
.t60 { margin-top: 60px !important; }
.t70 { margin-top: 70px !important; }
.t80 { margin-top: 80px !important; }
.t90 { margin-top: 90px !important; }

.b15 { margin-bottom: 15px !important; }
.b20 { margin-bottom: 20px !important; }
.b30 { margin-bottom: 30px !important; }
.b60 { margin-bottom: 60px !important; }

.l15 { margin-left: 15px !important; }
.r15 { margin-right: 15px !important; }

.pl20 { padding-left: 20px !important; }
.pr5  { padding-right: 5px !important; }
.pr10 { padding-right: 10px !important; }
.pr20 { padding-right: 20px !important; }
